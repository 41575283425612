import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import { YKEmailForm } from 'components/pay'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
// import donateSB from './img/donate_sb.svg'
// import donateYM from './img/donate_ym.svg'
// import donatePP from './img/donate_pp.svg'
import SEO from 'components/seo'

export default withMainLayout(DonatePage)

function DonatePage() {
  return(
    <div className='container'>
      <SEO
        title='Поддержать проект'
      />
      <div className={css.donatePage}>
        <h1
          align='center'
          style={{marginBottom: '50px'}}
        >
          Поддержать проект
        </h1>
        <h3
          align='center'
          style={{marginBottom: '15px'}}
        >
          Помогите внести свет в еще один дом!
        </h3>
        <p
          className={css.donateText}
        >
          Все полученные денежные средства пойдут на поддержку проекта Ханука, направленного на развитие и сохранение еврейской культуры и традиций
        </p>

        <div className={css.donateForm}>
          <YKEmailForm/>
        </div>
        <br />
        <br />
        <p className={cn('caption', css.donateSubText)}>
          Отправляя пожертвование, я подтверждаю, что ознакомлен с <a href='/public-offer' className={cn('caption', css.donateSubTextLink)} target='_blank' rel='noopener'>Публичной офертой</a>, и даю согласие на <a href='/privacy-policy' className={cn('caption', css.donateSubTextLink)} target='_blank' rel='noopener'>Обработку персональных данных</a>
        </p>
        <div className={css.donateQuote}>
          <h5 className={css.donateQuoteText}>
            «…не ожесточи сердца своего и не сожми руки твоей пред нищим братом твоим. Но открой ему руку свою и дай ему…» 
          </h5>
          <div className={css.donateQuoteTorah}>
            — (Тора, Дварим, 15 — 7; 8)
          </div>
        </div>
      </div>
    </div>
  );
}